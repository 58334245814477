<template>
  <TooltipButton
    @click="$emit('click')"
    :absolute="absolute"
    :bottom="bottom"
    :color="color"
    :disabled="disabled"
    :fab="true"
    :fixed="fixed"
    :icon-name="iconName"
    :icon="icon"
    :left="left"
    :loading="loading"
    :position-bottom="positionBottom"
    :position-left="positionLeft"
    :position-right="positionRight"
    :position-top="positionTop"
    :right="right"
    :small="small"
    :text="text"
    :top="top"
  />
</template>

<script>
export default {
  components: {
    TooltipButton: () => import('./TooltipButton.vue')
  },
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    positionBottom: {
      type: Boolean,
      default: false,
    },
    positionLeft: {
      type: Boolean,
      default: false,
    },
    positionRight: {
      type: Boolean,
      default: false,
    },
    positionTop: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
}
</script>